#logo {
    position: absolute;
    top: 18px;
    z-index: 90;
    border-radius: 100%;
}

.header {
    justify-content: space-between;
    align-items: center;
    background-color: var(--yellow-first-shade);
    padding: 0 0.5rem;
    margin-bottom: 70px;
    z-index: 10;
}

#language {
    font-weight: 500;
    font-size: 1rem;
}

.navbar {
    height: 70px;
    position: absolute !important;
    width: 100%;
    top: 40px;
    z-index: 50;
    transition: none;
    background-color: var(--black-clr);
}

.scroll {
    position: fixed !important;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: var(--white-clr);
}

.nav-link {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 7.5px 16px !important;
    margin-right: 3px;
    font-size: 13px !important;
    color: var(--white-clr) !important;
    transition: 0.75s;
}

.nav-link:hover,
.nav-link:focus {
    color: rgba(255, 255, 255, 0.7) !important;
}

.navbar-nav {
    max-height: none !important;
}

.dropdown-menu {
    top: 51px;
    background-color: rgba(13, 24, 38, 0.98);
}

.dropdown-item {
    text-transform: uppercase;
    color: var(--white-clr);
    font-size: 13px;
}

.dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--white-clr);
}

@media (max-width: 1020px) {
    .navbar-nav {
        width: 100%;
        position: absolute;
        top: 62px;
        left: 0;
        z-index: 50;
        margin: 0 !important;
    }

    .nav-link {
        background-color: #181818;
        margin: 0 !important;
    }

    .nav-link:hover {
        background-color: rgba(0, 0, 0, 1);
        color: #fff !important;
    }

    .nav-link {
        padding: 23.5px 0 !important;
        text-align: center;
    }
}