.contact-card {
    padding: 1rem;
    margin: 2rem 0;
    text-align: center;
}

.contact-card h3 {
    font-weight: 500 !important;
}

.contact-card div{
    color: #858585;
}