figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 460px;
  border-radius: 4px;
  box-shadow:
    0 10px 30px -10px rgba(0, 0, 0, 0.10),
    0 5px 15px -5px rgba(0, 0, 0, 0.20);
  background: #fff;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}

figure:after {
  content: "";
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  box-shadow:
    0 20px 40px -20px rgba(0, 0, 0, 0.20),
    0 10px 30px -10px rgba(0, 0, 0, 0.20);
  transition: opacity 0.25s linear;
}

figure:hover:after {
  opacity: 1;
}

figure:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

figure:hover img {
  transform: scale(1.05);
}

figure:hover figcaption {
  bottom: 0;
}

figure h1 {
  position: absolute;
  top: 50px;
  left: 20px;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 60px;
  font-weight: 100;
  line-height: 1;
}

figure img {
  height: 100%;
  width: 100%;
  transition: 0.25s;
  object-fit: cover;
}

figure figcaption {
  position: absolute;
  bottom: -245px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: white;
  line-height: 1;
  transition: 0.25s;
}

figure figcaption h3 {
  margin: 0 0 20px;
  padding: 0;
}

figure figcaption p {
  font-size: 14px;
  line-height: 1.75;
  overflow-y: auto;
  height: 200px;
}

figure figcaption button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
}