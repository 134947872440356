.schedule {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.schedule .schedule-item {
    display: flex;
    padding: 1rem;
    gap: 0.75rem;
    border-radius: 10px;
    width: max-content;
    flex-direction: column;
}

.schedule .schedule-item .schedule-item-time {
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: left;
    white-space: nowrap;
    width: min(30vmin, 10rem);
    color: var(--dim-white);
}

.schedule .schedule-item .schedule-item-desc-title {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--gold-clr);
    margin: 0;
    width: 100%;
    text-align: left;
}

.schedule .schedule-item .schedule-item-desc-text {
    color: #fff !important;
}

.schedule .schedule-item .schedule-item-desc {
    text-align: left;
}

@media (min-width: 768px) {

    .schedule .schedule-item {
        gap: 2rem;
        flex-direction: row;
    }

    .schedule .schedule-item .schedule-item-time,
    .schedule .schedule-item .schedule-item-desc-title {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

.schedule a {
    color: #f21d2f95;
    color: #7AC8F5 !important;
}