.container {
    max-width: 1170px;
    margin: auto;
}

ul {
    list-style: none;
}

.first h4::before {
    display: none;
}

.footer {
    background-color: #181818;
    padding: 70px 0;
    padding-bottom: 20px;
    line-height: 2;
}

footer .container {
    max-width: 1250px;
}

.footer-col {
    padding: 0 40px !important;
}

.footer-col ul {
    padding-left: 0;
}

.footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
}

.footer-col h4::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: var(--primary-clr);
    height: 2px;
    box-sizing: border-box;
    width: 100%;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a,
.footer-col p {
    font-size: 16px;
    text-decoration: none;
    font-weight: 300;
    color: #ababab !important;
    display: block;
    transition: all 0.3s ease;
}

.footer-col p {
    margin-top: 1rem;
}

.footer-col ul li a:hover {
    color: #ffffff !important;
}

.social-links {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    color: #ffffff;
    transition: all 0.5s ease;
}

.social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}

#lstFooter {
    background: #202020;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    position: relative;
}

#top-btn {
    display: inline-block;
    background-color: #202020;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 400px;
    position: absolute;
    top: -35px;
    left: center;
    transition: background-color .3s,
        opacity .5s, visibility .5s;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

#top-btn:hover {
    cursor: pointer;
    background-color: #333;
}

#top-btn:active {
    background-color: #555;
}

/*responsive*/
@media (max-width: 1020px) {
    .footer-col {
        width: 50% !important;
        margin-bottom: 30px;
    }
}

@media (max-width: 574px) {
    .footer-col {
        width: 100% !important;
    }
}

@media screen and (max-width: 800px) {

    #lstFooter>div>div>div,
    #insta-announcement,
    #lastFooter {
        flex-direction: column;
        align-items: center;
        margin: 0 !important;
    }

    #lstFooter>div>div>div {
        flex-direction: column !important;
    }

    #instaFeed {
        margin: 0 0.5rem;
        padding: 0 0.5rem;
    }


}